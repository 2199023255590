document.addEventListener('DOMContentLoaded', function () {
  Sceon.closeSidenav = function () {
    document.body.classList.remove('overflow-hidden', 'sidebar-open');
  };

  Sceon.openSidenav = function () {
    document.body.classList.add('overflow-hidden', 'sidebar-open');
  };

  document.querySelector('.hamburger-menu').addEventListener('click', () => {
    document.body.classList.contains('sidebar-open')
      ? Sceon.closeSidenav()
      : Sceon.openSidenav();
  });

  document
    .querySelector('.sidebar a:not(.hamburger-menu)')
    .addEventListener('click', () => Sceon.closeSidenav());

  window.addEventListener('resize', () => {
    if (
      window.innerWidth >= 1024 &&
      document.body.classList.contains('sidebar-open')
    ) {
      Sceon.closeSidenav();
    }
  });
});
